import * as React from "react";
import { Parallax } from "react-parallax";
import { Col, Container, Row } from "react-bootstrap";

import image from "../images/world.jpg";
import securitySvg from "../images/security.svg";
import Layout from "../components/layout";
import Section from "../components/section";
import Typography from "../components/typography";
import ScheduleForm from "../components/scheduleForm";

const LoanOfficers = () => {
  return (
    <Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
      <Section className="partner-page-container" color="light" staticHeight>
        <Parallax
          className="d-flex justify-content-center align-items-center h-100"
          bgImage={image}
          strength={750}
        >
          <Row className="d-flex h-100">
          <Col className= "PartnerCol">
              <Typography type="head" className="nom">
                Connections For <br/> Loan 
                Officers through Fundingverse
              </Typography>
              <Typography type="head" className="hide-on-desktop text-center">
                Loan Officer Connections
              </Typography>
            </Col>
            <Col>
              <ScheduleForm to="" />
            </Col>
          </Row>
        </Parallax>
      </Section>
      <Section size="sm" color="light" bg="dark">
        <Container className="text-center py-5">
          <Typography type="title">
            Benefits of A Fundingverse Partnership
          </Typography>
          <ul className="text-start">
            <li className="text-light">
              Make commissions from Fundingverse, while enhancing the ways you
              can help businesses in your communities.
            </li>
            <li className="text-light">
              If your bank turns away customers due to risk or timing, connect
              them with reputable alternative lenders to solve their problems.
            </li>
            <li className="text-light">
              Create a database of future customers &amp; relationships for
              free.
            </li>
          </ul>
        </Container>
      </Section>
      <Section size="md" staticHeight className="nom">
        <Container className="nom d-flex flex-nowrap h-100 align-items-center h-100 py-5">
          <div className="nom d-inline-block" style={{ flex: 0.9 }}>
            <img
              className="h-100"
              style={{ maxHeight: "300px" }}
              src={securitySvg}
              alt="A Man Standing Beside A Massive Security Sticker"
            ></img>
          </div>
          <div className="d-inline-block">
            <Typography type="title">Free. Fast. Secure.</Typography>
            <ul>
              <li>Software hosted in secure Amazon Web Services servers</li>
              <li>Takes 30 seconds to send a leads to us</li>
              <li>Real-time tracking to securely reacquire prior turn-downs</li>
            </ul>
          </div>
        </Container>
      </Section>

      <Section size="md" className="hide-on-desktop">
        <Container className="my-5">
          <div className="mb-3" style={{ flex: 0.9 }}>
            <img
              className="w-100"
              style={{ maxHeight: "200px" }}
              src={securitySvg}
              alt="A Man Standing Beside A Massive Security Sticker"
            ></img>
          </div>
          <div className="mx-4">
            <Typography type="title">Free. Fast. Secure.</Typography>
            <ul>
              <li>Software hosted in secure Amazon Web Services servers</li>
              <li>Takes 30 seconds to send a leads to us</li>
              <li>Real-time tracking to securely reacquire prior turn-downs</li>
            </ul>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default LoanOfficers;
